<template>
  <div id="team" class="py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
    <div class="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
      <div class="space-y-5 sm:space-y-4">
        <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">Our Team</h2>
        <p class="text-xl text-gray-400">
          Diamond Hands Hotel was created by five friends who set out to make some dope art and get out our parents basements.
        </p>
      </div>
      <div class="lg:col-span-2">
        <ul class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
          <li v-for="person in people" :key="person.name">
            <div class="space-y-4">
              <div class="aspect-w-4 aspect-h-3 pointer-events-none">
                <img class="object-cover shadow-lg rounded-lg" :src="person.imageUrl" alt="" />
              </div>
              <div class="text-lg leading-6 font-medium space-y-1">
                <h3>{{ person.name }}</h3>
                <p class="text-pink-600">{{ person.role }}</p>
              </div>
              <div class="text-lg">
                <p class="text-gray-400">{{ person.bio }}</p>
              </div>
              <ul v-if="person.twitter" role="list" class="flex space-x-5">
                <li>
                  <a :href="`https://twitter.com/${person.twitter}`" target="_blank" class="text-gray-400 hover:text-gray-500">
                    <span class="sr-only">Twitter</span>
                    <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import snakespear from '@/assets/img/team/snakespear.png'
import noodle from '@/assets/img/team/noodle.png'
import rubi from '@/assets/img/team/rubi.png'
import hissy from '@/assets/img/team/hissy.png'
import pretzel from '@/assets/img/team/pretzel.png'

const people = [
  {
    name: 'Snakespear',
    role: 'Mission Specialist',
    imageUrl: snakespear,
    bio: '6+ years working in the crypto space. Came for the bear memes, stayed for the bull memes.',
    twitter: 'SnakespearSays',
  },
  {
    name: 'Yung Noodle',
    role: 'Flight Engineer',
    imageUrl: noodle,
    bio: 'Head of hotel operations. No time for late check-ins.',
    twitter: 'ThaYungNoodle',
  },
  {
    name: 'Rubi B(OA)',
    role: 'Creative Sergeant',
    imageUrl: rubi,
    bio: 'Sr. Arts Officer and social enigma. Makes dope sneks & gets more likes than you.',
    twitter: 'RealRubiBoa',
  },
  {
    name: 'Pretzel',
    role: 'Friendship Director',
    imageUrl: pretzel,
    bio: 'Director of agency relationships. Keeps our creators happy, and fans even happier.',
  },
  {
    name: 'Hissy Bozo',
    role: 'Moon Ops',
    imageUrl: hissy,
    bio: 'Marketing mission control. Will slither into your DMs.',
  },
]
</script>

<template>
  <div class="relative py-16 lg:py-24">
    <div class="lg:mx-auto lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
      <div class="relative sm:py-16 lg:py-0">
        <div aria-hidden="true" class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
          <div class="absolute inset-y-0 right-1/2 w-full bg-gray-800 rounded-r-3xl lg:right-72" />
          <svg class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
            <defs>
              <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-400" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
          </svg>
        </div>
        <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
          
          <div class="grid grid-cols-2 gap-4">
            <div class="relative aspect-w-1 aspect-h-1 rounded-2xl shadow-xl overflow-hidden pointer-events-none">
              <img class="absolute inset-0 h-full w-full object-cover" src="@/assets/img/demo/ksi.jpg" alt="" />
            </div>
            <div class="relative paspect-w-1 aspect-h-1 rounded-2xl shadow-xl overflow-hidden pointer-events-none">
              <img class="absolute inset-0 h-full w-full object-cover" src="@/assets/img/demo/doja.jpg" alt="" />
            </div>
            <div class="relative aspect-w-1 aspect-h-1 rounded-2xl shadow-xl overflow-hidden pointer-events-none">
              <img class="absolute inset-0 h-full w-full object-cover" src="@/assets/img/demo/poki.jpg" alt="" />
            </div>
            <div class="relative aspect-w-1 aspect-h-1 rounded-2xl shadow-xl overflow-hidden pointer-events-none">
              <img class="absolute inset-0 h-full w-full object-cover" src="@/assets/img/demo/danny.jpg" alt="" />
            </div>
          </div>
          <div class="mt-3 font-semibold tracking-wide uppercase text-xs text-gray-500">
            * Illustration Only, Not for Sale
          </div>
        </div>
      </div>

      <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
        
        <div class="pt-12 sm:pt-16 lg:pt-20">
          <h2 class="text-3xl text-white font-extrabold tracking-tight sm:text-4xl">
            We create stunning, bespoke digital collectibles
          </h2>
          <div class="mt-6 text-gray-400 space-y-6">
            <p class="text-lg">
              <span class="text-gray-100">
                Diamond Hands Hotel help creators, influencers and celebrities launch their own line of NFTs
              </span> – a unique digital collectible that lives on the Ethereum blockchain.
            </p>
            <p class="text-lg">
              We handle every stage of the process, including designing bespoke collection in your style, building custom features, listing your collection on marketplaces, providing a drop website and processing payments.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const stats = [
  { label: 'Founded', value: '2021' },
  { label: 'Employees', value: '5' },
  { label: 'Beta Users', value: '521' },
  { label: 'Raised', value: '$25M' },
]

export default {
  setup() {
    return {
      stats,
    }
  },
}
</script>
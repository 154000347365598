
<template>
  <div class="max-w-7xl mx-auto px-4 pt-14 pb-24 sm:px-6 lg:px-8">
    <div class="bg-gray-800 rounded-2xl px-6 py-16 sm:p-16">
      <div class="max-w-xl mx-auto lg:max-w-none">
        <div class="text-center">
          <h2 class="text-2xl font-extrabold tracking-tight text-gray-200">
            How are Diamond Hands Hotel NFTs made?
          </h2>
        </div>
        <div class="mt-12 max-w-sm mx-auto grid grid-cols-1 gap-y-10 gap-x-8 sm:max-w-none lg:grid-cols-3">
          <div class="text-center sm:flex sm:text-left lg:block lg:text-center">
            <div class="sm:flex-shrink-0">
              <div class="flow-root">
                <img class="w-16 h-16 mx-auto" src="@/assets/img/icon/alien.svg" alt="" />
              </div>
            </div>
            <div class="mt-3 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
              <h3 class="text-lg font-medium text-gray-200">
                Creature
              </h3>
              <p class="mt-2 text-base text-gray-400">
                Your pet, spirit animal, favourite cartoon character, an alien or something completely made up
              </p>
            </div>
          </div>
          <div class="text-center sm:flex sm:text-left lg:block lg:text-center">
            <div class="sm:flex-shrink-0">
              <div class="flow-root">
                <img class="w-16 h-16 mx-auto" src="@/assets/img/icon/choose.svg" alt="" />
              </div>
            </div>
            <div class="mt-3 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
              <h3 class="text-lg font-medium text-gray-200">
                Traits
              </h3>
              <p class="mt-2 text-base text-gray-400">
                Facial expressions, clothing, accessories & more - pick your favourites, and make them extra rare
              </p>
            </div>
          </div>
          <div class="text-center sm:flex sm:text-left lg:block lg:text-center">
            <div class="sm:flex-shrink-0">
              <div class="flow-root">
                <img class="w-16 h-16 mx-auto" src="@/assets/img/icon/art.svg" alt="" />
              </div>
            </div>
            <div class="mt-3 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
              <h3 class="text-lg font-medium text-gray-200">
                Art Style
              </h3>
              <p class="mt-2 text-base text-gray-400">
                Pick an art style from our examples, or work with our designers to create something custom
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<template>
  <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 lg:py-24">
    <div class="lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div class="lg:col-span-1">
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-100 sm:text-4xl">
          An expandable platform
        </h2>
        <p class="mt-3 text-lg text-gray-400">
          Turn your latest music video or upcoming album into an NFT.
        </p>
      </div>
      <dl class="mt-10 grid grid-cols-2 gap-x-8 gap-y-10 lg:mt-0 lg:col-span-2">
        <div v-for="feature in features" :key="feature.name">
          <dt>
            <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
              <component :is="feature.icon" class="h-6 w-6" aria-hidden="true" />
            </div>
            <p class="mt-5 text-lg leading-6 font-medium text-gray-100">{{ feature.name }}</p>
          </dt>
          <dd class="mt-2 text-base text-gray-400">
            {{ feature.description }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { MusicNoteIcon, DocumentDownloadIcon, CubeIcon, VideoCameraIcon } from '@heroicons/vue/outline'

const features = [
  {
    name: 'Music',
    description: 'Include a single track or full album with the purchase of your NFT.',
    icon: MusicNoteIcon,
  },
  {
    name: 'Videos',
    description: 'Your NFT can also grant access to an exclusive video or series.',
    icon: VideoCameraIcon,
  },
  {
    name: 'Downloads',
    description: 'Attach any digital file to your NFT, and only grant access to holders.',
    icon: DocumentDownloadIcon,
  },
  {
    name: 'Physical products',
    description: 'Include your NFT with a poster, apparel or anything else with redeem codes.',
    icon: CubeIcon,
  },
]

export default {
  setup() {
    return {
      features,
    }
  },
}
</script>
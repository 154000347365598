
<template>
  <div class="py-16 lg:py-24">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto text-center">
        <h2 class="text-3xl font-extrabold text-gray-100">So... it's more than a JPEG?</h2>
        <p class="mt-4 text-lg text-gray-400">
          When fans buy a Diamond Hands Hotel NFT, they're not just buying an avatar or provably-unique piece of art. They're getting lifetime access to
          a private club whose benefits will increase over time. 
        </p>
      </div>
      <dl class="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
        <div v-for="feature in features" :key="feature.name" class="relative">
          <dt>
            <CheckIcon class="absolute h-6 w-6 text-blue-400" aria-hidden="true" />
            <p class="ml-9 text-lg leading-6 font-medium text-gray-100">{{ feature.name }}</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-400">
            {{ feature.description }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/outline'

const features = [
  {
    name: 'Provably unique',
    description: 'All designs are unique, and each drop has very limited numbers',
  },
  {
    name: 'Real ownership',
    description: 'Ownership and commercial usage rights given to token holders',
  },
  {
    name: 'Resale market',
    description: 'Tokens can be bought and sold on the secondary market',
  },
  {
    name: 'Fair price',
    description: 'All tokens are sold for the same price during the drop, set by the creator',
  },
]

export default {
  components: {
    CheckIcon,
  },
  setup() {
    return {
      features,
    }
  },
}
</script>
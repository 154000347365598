<template>
  <div class="py-16 lg:py-24">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="relative">
        <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div class="z-10 relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 md:mt-10 lg:px-0 lg:max-w-none lg:mt-0 lg:col-start-2">
            <svg class="hidden md:block absolute right-0 transform -translate-y-16 translate-x-1/3 " width="204" height="392" fill="none" viewBox="0 0 204 392" aria-hidden="true">
              <defs>
                <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-400" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="204" height="392" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
            </svg>
            <div class="relative w-full rounded-lg bg-gray-800 px-6 py-8">
              <nav aria-label="Progress">
                <ol role="list" class="overflow-hidden">
                  <li v-for="(step, stepIdx) in steps" :key="step.name" :class="[stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative']">
                    <template v-if="step.status === 'complete'">
                      <div v-if="(stepIdx !== steps.length - 1)" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-teal-500" aria-hidden="true" />
                      <div class="relative flex items-start group">
                        <span class="h-9 flex items-center">
                          <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-teal-500 rounded-full">
                            <CheckIcon class="w-5 h-5 text-white" aria-hidden="true" />
                          </span>
                        </span>
                        <span class="ml-4 min-w-0 flex flex-col">
                          <span class="text-xs font-semibold tracking-wide uppercase">
                            <span class="text-teal-500">{{ step.stage }}%</span>
                            {{ step.name }}
                          </span>
                          <span class="text-sm text-gray-300">{{ step.description }}</span>
                        </span>
                      </div>
                    </template>
                    <template v-else-if="step.status === 'current'" condition="step.status === 'current'">
                      <div v-if="(stepIdx !== steps.length - 1)" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                      <div class="relative flex items-start group" aria-current="step">
                        <span class="h-9 flex items-center" aria-hidden="true">
                          <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-gray-800 border-2 border-teal-500 rounded-full">
                            <span class="h-2.5 w-2.5 bg-teal-500 rounded-full" />
                          </span>
                        </span>
                        <span class="ml-4 min-w-0 flex flex-col">
                          <span class="text-xs font-semibold tracking-wide uppercase">
                            <span class="text-teal-500">{{ step.stage }}%</span>
                            {{ step.name }}
                          </span>
                          <span class="text-sm text-gray-300">{{ step.description }}</span>
                        </span>
                      </div>
                    </template>
                    <template v-else>
                      <div v-if="(stepIdx !== steps.length - 1)" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                      <div class="relative flex items-start group">
                        <span class="h-9 flex items-center" aria-hidden="true">
                          <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-gray-800 border-2 border-gray-300 rounded-full">
                            <span class="h-2.5 w-2.5 bg-transparent rounded-full" />
                          </span>
                        </span>
                        <span class="ml-4 min-w-0 flex flex-col">
                          <span class="text-xs font-semibold tracking-wide uppercase text-gray-400">
                            <span class="text-gray-500">{{ step.stage }}%</span>
                            {{ step.name }}
                          </span>
                          <span class="text-sm text-gray-300">{{ step.description }}</span>
                        </span>
                      </div>
                    </template>
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div class="relative mx-auto max-w-md px-4 pt-12 sm:max-w-3xl sm:px-6 sm:pt-16 lg:px-0 lg:col-start-1 lg:pt-0">
            <div>
              <span class="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-tr from-teal-400 to-teal-600">
                <LockOpenIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </span>
            </div>
            <div class="mt-6">
              <h2 class="text-3xl font-extrabold tracking-tight text-white">
                Unlock rewards as your drop sells
              </h2>
              <p class="mt-4 text-lg text-gray-400">
                Control how many of your tokens need to sell before your rewards are unlocked, and incentivise early collectors to promote your drop until all rewards are unlocked.
              </p>
              <div class="mt-6">
                <a href="mailto:apply@diamondhandshotel.com" class="inline-flex bg-gradient-to-tr from-teal-400 to-teal-600 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-teal-500 hover:to-teal-700">
                  Get started
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { LockOpenIcon } from '@heroicons/vue/outline'
import { CheckIcon } from '@heroicons/vue/solid'

const steps = [
  { name: 'Random airdrop', description: 'Five top secret tokens given to random fans', status: 'complete', stage: '20' },
  { name: 'Merch drop', description: 'Limited merch drop, just for holders', status: 'complete', stage: '40' },
  { name: 'Graffiti wall', description: 'Unlock access to the graffiti wall', status: 'current', stage: '60' },
  { name: 'Store discount', description: 'Get 20% off everything on my store', status: 'upcoming', stage: '80' },
  { name: 'Live stream', description: 'Exclusive Q&A stream with the creator', status: 'upcoming', stage: '100' },
]
</script>

<template>
  <div id="faq" class="py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
    <div class="max-w-3xl mx-auto">
      <h2 class="text-center text-3xl font-extrabold text-gray-100 sm:text-4xl">
        Frequently asked questions
      </h2>
      <dl class="mt-6 space-y-6 divide-y divide-gray-700">
        <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
          <dt class="text-lg">
            <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-200">
              <span class="font-medium text-gray-100">
                {{ faq.question }}
              </span>
              <span class="ml-6 h-7 flex items-center">
                <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
              </span>
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" class="mt-2 pr-12">
            <p class="text-base text-gray-400" v-html="faq.answer"></p>
          </DisclosurePanel>
        </Disclosure>
      </dl>
    </div>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/outline'

const faqs = [
  {
    question: 'What is an NFT?',
    answer: 'An NFT is a provably-unique digital collectible that lives on the Ethereum blockchain. They can be transferred, bought and sold like any physical collectible, and cannot be faked.',
  },
  {
    question: 'How many NFTs are released per drop?',
    answer: 'It entirely depends on the number of traits and variations the creator wants. We recommend a minimum of 1000, but can go as big as you like!',
  },
  {
    question: 'How much will each NFT sell for?',
    answer: 'The initial retail price is up to the creator. We recommend pricing your first drop between $100 and $400 per NFT depending on your audience and the size of the drop.',
  },
  {
    question: 'What about auctions or 1-of-1 drops?',
    answer: 'We can do this too! Contact us for more information on creating a bespoke artwork.',
  },
  {
    question: 'Can fans only buy a NFT if they already have Ethereum?',
    answer: 'Nope! All our drops also offer credit/debit card payment options.',
  },
  {
    question: 'Isn\'t crypto really complicated?',
    answer: 'Not anymore! We work with you to design your collection, manage all the technology, host your drop, process payments and handle customer support.',
  },
  {
    question: 'What are your fees?',
    answer: 'We take a small commission on the sales of every drop. Each drop is bespoke, so fees vary depending features, size and art. Please contact us for more information.',
  },
  {
    question: 'Can I take a cut of resales to?',
    answer: 'Sure! Turn your sales into recurring revenue by taking a small cut each time one of your tokens is resold. Contact us for more information.',
  },
  {
    question: 'How long does a drop take from start to finish?',
    answer: 'Roughly one month. It usually takes our designers two weeks to create the artwork, and we recommend you promote your upcoming drop for at least two weeks.',
  },
  {
    question: 'Where will my drop be hosted?',
    answer: 'Every drop includes a sale page (<a href="https://sneks.diamondhandshotel.com" target="_blank" class="text-pink-500">like this one</a>) hosted by DHH. We can also use your existing domain, or set up a new one just for the drop.',
  },
  {
    question: 'Can I use my own domain?',
    answer: 'Yes! We can set up your drop on a domain or subdomain you already own, or register a new one just for your drop.',
  },
  {
    question: 'How do token holders access the Diamond Hands Hotel?',
    answer: 'Token holders can create or sign in to their Diamond Hands Hotel account once the drop is over, or prove their token ownership with Metamask.',
  },
]
</script>